import React from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { logout, unmascaradeUser } from "@modules/session";
import { history } from "@modules/";
import api from "@api/index";
import styled from "styled-components";

const LogOutHeader = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const logOut = async () => {
    dispatch(logout());
    history.push("/");
  };
  const unMask = () => {
    dispatch(unmascaradeUser());
    history.push("/");
  };

  const mascaradeToken = api.getMaskToken();

  return (
    <>
      {user.id ? (
        <>
          {children}
          <LogOutTrigger>
            <a href="#">
              <span className="user-name">{user.firstName}</span>
              <span className="divider"> - </span>
              <span
                className="no-wrap"
                onClick={mascaradeToken ? unMask : logOut}
              >
                {mascaradeToken ? `UNMASK` : `LOG OUT`}
              </span>
            </a>
          </LogOutTrigger>
        </>
      ) : null}
    </>
  );
};

export default LogOutHeader;

const LogOutTrigger = styled.li`
  padding: 0px;
  margin: 0px 10px;

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    height: 40px;
    padding: 0 18px;
    border-radius: 20px;
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    display: block;
    color: #fff;
  }

  .user-name {
    text-transform: capitalize;
  }

  .divider {
    margin: 0 5px;
  }
`;
