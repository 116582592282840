import React from "react";
import styled from "styled-components";

const LoadingSpinner = ({ center }) => {
  return (
    <LoaderSpinner center>
      <LoaderRotate>
        <i className="icon-loading" />
      </LoaderRotate>
    </LoaderSpinner>
  );
};

export default LoadingSpinner;

const LoaderSpinner = styled.div`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.center ? "100%" : "auto")};
`;

const LoaderRotate = styled.div`
  font-size: 30px;
  width: 30px;
  margin: 0 auto 20px;
  -webkit-animation: rotate 1s infinite linear;
  animation: rotate 1s infinite linear;

  i {
    color: #336cfb;
  }

  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
