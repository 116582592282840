import React, { useState } from "react";
import styled from "styled-components";
import LogoHeaderImg from "@assets/images/lndn-2024/brand-docto-lndn-white-2024.svg";
import IconNavTrigger from "@assets/images/lndn-2024/nav-trigger-white.svg";
import Account from "./account";
import DoctorNavigation from "@src/layouts/navigation/doctor";
import PatientNavigation from "@src/layouts/navigation/patient";
import AdminDashboardNavigation from "@src/layouts/navigation/admin_app";
import NurseAppNavigation from "@src/layouts/navigation/nurses_app";
import InstantDoctorAppNavigation from "@src/layouts/navigation/instant_doctor_app";
import CompanyAdminNav from "@layouts/navigation/company_admin_nav";
import { useSelector } from "react-redux";
import UserHelpers from "@helpers/user";

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const user = useSelector((state) => state.session.user);

  const toggleSubNav = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const isDoctor = UserHelpers.isDoctor(user);
  const isAdmin = UserHelpers.isAdmin(user);
  const isPatient = UserHelpers.isPatient(user);
  const isNurse = UserHelpers.isNurse(user);
  const isInstantDoctor = UserHelpers.isInstantDoctor(user);
  const isCompanyAdmin = UserHelpers.isCompanyAdmin(user);

  return (
    <HeaderWrap>
      <div className="header-container">
        <a className="logo-link" href="/">
          <img
            className="logo"
            width="200"
            height="40"
            src={LogoHeaderImg}
            alt="docto-logo"
          />
        </a>

        <div className="nav-trigger" onClick={() => setShowMenu(!showMenu)}>
          <img
            width="24"
            height="22"
            src={IconNavTrigger}
            alt="nav trigger icon"
          />
        </div>

        <ul className={showMenu ? "nav active" : "nav"}>
          {showMenu && <Account mobile />}
          {isDoctor && (
            <DoctorNavigation
              toggleSubNav={toggleSubNav}
              activeIndex={activeIndex}
            />
          )}
          {isNurse && (
            <NurseAppNavigation
              toggleSubNav={toggleSubNav}
              activeIndex={activeIndex}
            />
          )}
          {isInstantDoctor && (
            <InstantDoctorAppNavigation
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />
          )}
          {isCompanyAdmin && (
            <CompanyAdminNav
              toggleSubNav={toggleSubNav}
              activeIndex={activeIndex}
            />
          )}
          {isAdmin && (
            <AdminDashboardNavigation
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              toggleSubNav={toggleSubNav}
            />
          )}
          {isPatient && (
            <PatientNavigation
              toggleSubNav={toggleSubNav}
              activeIndex={activeIndex}
            />
          )}

          <Account />
        </ul>
      </div>
    </HeaderWrap>
  );
};

export default Header;

const HeaderWrap = styled.div`
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0;
  background: #336cfb;

  @media (max-width: 1199px) {
    height: 75px;
  }

  .header-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1199px) {
    position: fixed;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    max-width: none;
  }
  a.logo-link {
    line-height: 1;

    img.logo {
      max-height: 40px;
      margin: 0;

      @media (max-width: 1199px) {
        height: 32px;
        width: 122px;
      }
    }
  }
  ul.nav {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 0 0 auto;
    height: 100%;

    @media (max-width: 1199px) {
      display: none;
      top: 75px;
      left: 0;
      width: 100%;
      padding: 20px;
      background: #336cfb;
      border-bottom: 1px solid #dedede;

      &.active {
        display: block;
        position: absolute;
        height: calc(100vh - 75px);
        width: 100%;
        overflow: scroll;
      }
    }
  }

  .nav-trigger {
    display: flex;
    align-items: center;
    margin-left: auto;

    img {
      width: 24;
      height: 22px;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .sub-nav {
    display: none;
    position: absolute;
    align-items: flex-start;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 40px 60px;
    background: #fff;
    border-top: 1px solid #dedede;

    &:before {
      content: "";
      position: absolute;
      top: -10px;
      left: 10px;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent rgba(255, 2555, 255, 92%)
        transparent;
    }

    @media (max-width: 1199px) {
      display: none;
      position: initial;
      background: none;
      padding: 0;
      border: none;

      &:before {
        display: none;
      }
    }
  }

  .sub-nav-title {
    width: 33%;
    padding-right: 50px;

    @media (max-width: 1199px) {
      display: none;
    }

    h4 {
      color: #336cfb;
      font-size: 30px;
      margin-bottom: 20px;
    }

    p {
      color: #787878;
      font-size: 16px;
      font-weight: 300;
    }
  }

  ul.sub-nav-links {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    @media (max-width: 1199px) {
      flex-direction: column;
    }

    .subnav-item {
      padding: 0;
      text-align: left;
      width: 20%;

      @media (max-width: 1199px) {
        width: auto;
        text-align: left;
        padding: 0;
      }

      &:last-child {
        border: none;
      }

      a {
        display: block;
        padding: 12px 12px;
        color: #787878;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.2;

        @media (max-width: 1199px) {
          padding: 5px 0;
          color: #fff;
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }

        &:hover {
          color: #336cfb;
          text-decoration: none;

          @media (max-width: 1199px) {
            color: #fff;
          }
        }
      }

      img {
        height: 12px;
        margin-right: 5px;
      }
    }

    .subnav-item-large {
      padding: 10px;
      width: 33%;

      @media (max-width: 1199px) {
        width: auto;
        text-align: left;
        padding: 0;
      }

      a {
        display: block;
        padding: 10px;
        border-radius: 10px;

        @media (max-width: 1199px) {
          padding: 5px 0;
          color: #fff;
          mzargin-bottom: 5px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 0;
        }

        &:hover {
          color: #001a78;
          text-decoration: none;
          background: #e5f2fe;

          @media (max-width: 1199px) {
            color: #fff;
            background: none;
          }

          b {
            color: #001a78;

            @media (max-width: 1199px) {
              color: #fff;
            }
          }
        }

        b {
          display: block;
          color: #525252;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.2;
          margin-bottom: 20px;

          @media (max-width: 1199px) {
            margin: 0;
            color: #fff;
            font-size: 14px;
            font-weight: 300;
          }
        }

        span {
          display: block;
          color: #787878;
          font-size: 13px;
          font-weight: 400;
          line-height: 1.2;

          @media (max-width: 1199px) {
            display: none;
          }
        }
      }

      @media (max-width: 1199px) {
      }
    }
  }

  .nav-item {
    padding: 0;
    margin: 0 10px;
    height: 100%;

    > a {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 20px 5px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;

      @media (max-width: 1199px) {
        height: auto;
        padding: 0;
        color: #fff;
        margin-bottom: 5px;
        width: 100%;
        font-size: 22px;

        &:hover {
          color: #fff;
        }
      }

      i {
        font-size: 12px;
        margin-left: 5px;

        @media (max-width: 1199px) {
          margin-left: auto;
          rotate: -90deg;
        }
      }

      &:hover {
        color: #fff;
        text-decoration: none;

        @media (max-width: 1199px) {
          color: #fff;
        }
      }
    }

    button {
      min-width: 120px;
      padding: 5px 20px;

      @media (max-width: 1199px) {
        color: #336cfb;
        background: #fff;
      }
    }

    @media (min-width: 1023px) {
      &.mobile {
        display: none;
      }
    }

    @media (max-width: 1199px) {
      display: block;
      height: auto;
      padding: 0;
      font-size: 18px;
      text-align: left;
      border-bottom: none;
      margin: 0 0 20px 0;

      &.hide-mobile {
        display: none;
      }

      &:last-child {
        border: none;
      }
    }

    @media (min-width: 1200px) {
      &:hover {
        .sub-nav {
          display: flex;
        }
      }
    }

    @media (max-width: 1199px) {
      &.active {
        i {
          rotate: 0deg;
        }

        .sub-nav {
          display: flex;
        }
      }
    }
  }
`;
