import styled from "styled-components";
import IconCaretDownBlack from "@assets/images/icon-angle-down.svg";

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    display: block;
    font-size: 10px;
    margin-bottom: 2px;
    color: #52575c;
    border-color: transparent;
    margin: 5px 0;
    text-align: left;
  }

  .autocomplete-wrap {
    display: block !important;
    width: 100%;
    position: relative;
    .bot-drop-toggle {
      width: 100% !important;
    }
  }
  .pac-container {
    z-index: 999999999999999999;
    top: initial !important;
    bottom: 100% !important;
    border-radius: 5px;
    left: 0 !important;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  #divAutoComplete {
    position: relative;
  }
  #divAutoComplete .pac-container {
    top: initial !important;
    bottom: 100% !important;
    border-radius: 5px;
    left: 0 !important;
    margin-bottom: 10px;
  }
`;

export const FormGroupStyled = styled.div`
  margin-bottom: 20px;
  position: relative;
  color: #52575c;

  .form-group {
    margin-bottom: 20px;
  }

  &.inline {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label {
      margin: 0 10px 0 0;
    }
  }

  label {
    display: block;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    margin-bottom: 5px;
  }

  .hidden-input {
    span {
      font-weight: 700;
      a {
        color: #db4646;
        font-weight: 300;
        text-decoration: underline;
      }
    }
  }

  .multiple-wrap {
    width: 100%;
    text-align: left;
  }

  .col-1-4 {
    width: 75px;
    margin-right: 5px;
  }

  .col-2-4 {
    width: 120px;
    margin-right: 5px;
  }

  .col-3-4 {
    width: 240px;
  }

  .col-2-2 {
    width: 156px;
    margin-right: 7px;

    &:last-child {
      margin: 0;
    }
  }

  input,
  select,
  textarea,
  .Select-control,
  .styledSelect .css-13cymwt-control {
    width: 100%;
    border: none;
    outline: none;
    border-radius: 10px;
    height: 40px;
    background: #f9f9f9;
    border: 1px solid #a0a4a8;
    padding: 0 10px;
    font-weight: 400;

    &:focus {
      border: 1px solid rgba(51, 108, 251, 0.85);
    }

    &[type="checkbox"] {
      width: auto;
      height: auto;
    }
  }

  .styledSelect div[class$="-control"],
  .styledSelect div[class$="-is-focused"] {
    min-height: 0;
    height: 42px;
    padding: 0 10px;
    border-radius: 10px;

    div[class$="-ValueContainer"] {
      height: 38px;
      padding: 0;
    }

    .docto__input-container {
      height: 38px;
      min-height: 0;
      box-sizing: border-box;
    }
  }

  .ck-editor {
    .ck-toolbar {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      background: #f9f9f9;
    }

    .ck-content {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      background: #f9f9f9 !important;
    }
  }

  .slackSelect .docto__control {
    .docto__value-container {
      padding: 0;
    }

    input {
      height: 100%;
    }
  }

  textarea {
    padding: 10px;
    vertical-align: text-top;
    resize: none;
    height: 80px;
  }

  &:after {
    display: none;
    box-sizing: border-box;
    content: attr(data-alert);
    width: 100%;
    margin: 5px 0 0;
    padding: 6px 10px;
    border-radius: 4px;

    background: rgba(138, 0, 0, 0.15);

    font-size: 0.75em;
    text-align: left;
    color: #db4646;
  }

  &.alert-error {
    &:after {
      display: inline-block;
    }

    input {
      border: 1px solid #db4646;
    }
  }

  &.center {
    label {
      width: auto;
    }
  }

  &.has-error {
    &:after {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      content: "*";
      width: auto;
      margin: 14px 0 0 232px;
      padding: 0;
      background: none;
      font-size: 1.4em;
      text-align: center;
      color: #db4646;
    }
  }

  .Select--multi,
  .Select--single {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    text-align: left;

    .Select-control {
      width: 340px;
    }

    .Select-input {
      margin: 0;

      input {
        border: none;
        background: none;
        width: auto;
        padding: 0;
      }
    }

    .Select-value {
      margin: 6px 5px 5px 0;
    }
  }

  &.change-input {
    align-items: flex-start;

    label {
      margin-top: 25px;
    }

    &.passFix {
      label {
        margin-top: 13px;
      }
    }

    .hidden-input {
      a {
        float: right;
        margin-top: 5px;
        font-size: 0.875em;
        color: #13bec4;
        text-decoration: underline;
      }
    }
  }
`;

export const FormError = styled.div`
  display: "inline-block";

  margin: 5px 0px;
  padding: 6px 10px;
  background: rgba(255, 0, 0, 0.15);
  font-size: 0.75em;
  text-align: left;
  color: red;
  transition: border-color;
  border-radius: 5px;
`;

export const FormSuccess = styled.div`
  display: "inline-block";

  margin: 5px 0px;
  padding: 6px 10px;
  background: rgba(19, 156, 48, 0.1);
  border: 1px solid #139c30;
  color: #139c30;
  font-size: 0.75em;
  text-align: left;
  transition: border-color;
  border-radius: 5px;
`;

export const FormWarning = styled.div`
  display: "inline-block";
  margin: 5px 0px;
  padding: 6px 10px;
  background: rgb(250 228 0 / 54%);
  font-size: 0.75em;
  text-align: left;
  color: #c0810c;
  -webkit-transition: border-color;
  transition: border-color;
  border-radius: 5px;
`;

export const InputWrap = styled.div`
  display: flex;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  .before {
    position: absolute;
    line-height: 40px;
    padding: 0px 10px;
    font-size: small;
    color: #767676;
  }
`;

export const FormControl = styled.input`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 0 10px;
  margin-bottom: ${(props) => (props.clearMargin ? "0" : "15px")};
  background-color: ${(props) =>
    props.flat ? "#F6F8FB" : "rgb(249, 249, 249)"};
  border: 1px solid
    ${(props) => (props.flat ? "transparent" : "rgba(22, 23, 56, 0.22)")};
  transition: border-color;
  border-radius: 10px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  width: 100%;
  padding-left: ${(props) => (props.padded ? "60px" : "10px")};

  &:focus {
    outline: none;
    border: 1px solid rgba(22, 23, 56, 0.35);
  }
`;

export const SelectControl = styled.input`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  margin-bottom: ${(props) => (props.clearMargin ? "0" : "15px")};
  background: #f6f8fb;
  border: 1px solid transparent;
  transition: border-color;
  border-radius: 5px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;
  width: 100%;
  min-height: 42px;
  padding-left: ${(props) => (props.padded ? "55px" : "8px")};
  background-image: url(${IconCaretDownBlack});
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: calc(100% - 10px) 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline-width: 0;

  &:focus {
    outline: none;
    border: 1px solid rgba(22, 23, 56, 0.35);
  }
`;

export const PlainFormControl = styled.input`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 40px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

export const TextAreaFormControl = styled.textarea`
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  padding: 0 10px;
  margin-bottom: 15px;
  background-color: ${(props) => (props.flat ? "#F6F8FB" : "#fff")};
  border: 1px solid
    ${(props) => (props.flat ? "transparent" : "rgba(22, 23, 56, 0.22)")};
  transition: border-color;
  border-radius: 5px;
  font-size: 1em;
  line-height: 40px;
  box-sizing: border-box;
  height: auto;
  line-height: 22px;

  &:focus {
    outline: none;
    border: 1px solid rgba(22, 23, 56, 0.35);
  }
`;
