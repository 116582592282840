import React from "react";
import { Link } from "react-router-dom";
import User from "@helpers/user";
import { useSelector } from "react-redux";
import Logout from "./logout";

function Account() {
  const user = useSelector((state) => state.session.user);
  const isDoctor = User.isDoctor(user);
  const isAdmin = User.isAdmin(user);
  const isPatient = User.isPatient(user);
  const isNurse = User.isNurse(user);

  if (isDoctor) {
    return (
      <Logout>
        <li className="nav-item">
          <Link to="/">Dashboard</Link>
        </li>
      </Logout>
    );
  }
  if (isNurse) {
    return (
      <Logout>
        <li className="nav-item">
          <Link to="/">Dashboard</Link>
        </li>
      </Logout>
    );
  }
  if (isAdmin) {
    return (
      <Logout>
        <li className="nav-item">
          <Link to="/dashboard">Dashboard</Link>
        </li>
      </Logout>
    );
  }
  if (isPatient) {
    return (
      <Logout>
        <li className="nav-item ">
          <Link to="/account/dashboard">Dashboard</Link>
        </li>
        <li className="nav-item ">
          <Link to="/patient/appointments">Appointment</Link>
        </li>
        <li className="nav-item ">
          <Link to="/account/profile">Account</Link>
        </li>
      </Logout>
    );
  }
  return <Logout />;
}

export default Account;
