import React from "react";
import { Link } from "react-router-dom";
import NavItem from "@layouts/appLayoutStyled/header/nav_item";

function InstantDoctorNavigations({ activeIndex, toggleSubNav }) {
  return (
    <>
      <NavItem
        index={0}
        isActive={activeIndex === 0}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/">Dashboard</Link>
      </NavItem>
    </>
  );
}

export default InstantDoctorNavigations;
