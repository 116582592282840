export const SHOW_LOGIN_MODAL = "gui/SHOW_LOGIN_MODAL";
export const SHOW_EMERGENCY_BOT = "gui/SHOW_EMERGENCY_BOT";
export const SHOW_SPECIALTY_BOT = "gui/SHOW_SPECIALTY_BOT";
export const SHOW_CONFIRMATION_MODAL = "gui/SHOW_CONFIRMATION_MODAL";
export const SHOW_NEW_PATIENT = "gui/SHOW_NEW_PATIENT";
export const SHOW_LOADING_MODAL = "gui/SHOW_LOADING_MODAL";
export const SHOW_FILE_VIEWER = "gui/SHOW_FILE_VIEWER";
export const SHOW_PRICING_MODAL = "gui/SHOW_PRICING_MODAL";
export const SHOW_PAYMENT_MODAL = "gui/SHOW_PAYMENT_MODAL";
export const SHOW_POSTCODE_CHECKER = "gui/SHOW_POSTCODE_CHECKER";
export const SHOW_UPDATE_APPOINTMENTS = "gui/SHOW_UPDATE_APPOINTMENTS";
export const SHOW_CHAT_SIDE_BAR = "gui/SHOW_CHAT_SIDE_BAR";
export const TOGGLE_CHAT_SIDE_BAR = "gui/TOGGLE_CHAT_SIDE_BAR";
export const SHOW_ADVANCE_PAYMENT_MODAL = "gui/SHOW_ADVANCE_PAYMENT_MODAL";
export const SHOW_SPECIALTY_BOOKING_MODAL = "gui/SHOW_SPECIALTY_BOOKING_MODAL";
export const SHOW_DOCTOR_NOTES = "gui/SHOW_DOCTOR_NOTES";
export const SHOW_PATIENT_INFORMATION = "gui/SHOW_PATIENT_INFORMATION";
export const SHOW_PATIENT_MEDICAL_RECORDS = "gui/SHOW_PATIENT_MEDICAL_RECORDS";
export const SHOW_PATIENT_SURVEY = "gui/SHOW_PATIENT_SURVEY";
export const ADD_PARTICIPANT = "gui/ADD_PARTICIPANT";
export const SHOW_DOCTOR_SURVEY = "gui/SHOW_DOCTOR_SURVEY";
export const SHOW_GUEST_LINK_MODAL = "gui/SHOW_GUEST_LINK_MODAL";
export const SHOW_POST_CONSULT_LINK_MODAL = "gui/SHOW_POST_CONSULT_LINK_MODAL";

export const SHOW_DOCUMENT_VIEWER = "gui/SHOW_DOCUMENT_VIEWER";
export const SHOW_SPECIALTY_PRICING_MODAL = "gui/SHOW_SPECIALTY_PRICING_MODAL";
export const SHOW_MEDICARE_INSTRUCTIONS = "gui/SHOW_MEDICARE_INSTRUCTIONS";
export const SHOW_IN_MEETING = "gui/SHOW_IN_MEETING";
export const DISPLAY_CHAT_MESSAGE = "gui/DISPLAY_CHAT_MESSAGE";
export const SHOW_OFFER_REFERRAL_MODAL = "gui/SHOW_OFFER_REFERRAL_MODAL";
export const SHOW_AMEND_APPOINTMENT_MODAL = "gui/SHOW_AMEND_APPOINTMENT_MODAL";
export const SHOW_SEND_REFERRAL_MODAL = "gui/SHOW_SEND_REFERRAL_MODAL";
export const SHOW_UPDATE_APPOINTMET_MODAL = "gui/SHOW_UPDATE_APPOINTMENT_MODAL";
export const SHOW_PREAPROVED_DATES_MODAL = "gui/SHOW_PREAPROVED_DATES_MODAL";
export const SHOW_MARK_AS_COMPLETED_MODAL = "gui/SHOW_MARK_AS_COMPLETED_MODAL";
export const UPDATE_DOCUMENT_BUILDER = "gui/UPDATE_DOCUMENT_BUILDER";
export const SHOW_EMAIL_DOCUMENT_MODAL = "gui/EMAIL_DOCUMENT_MODAL";
export const SHOW_EMERGENCY_BOOKING_MODAL = "gui/SHOW_EMERGENCY_BOOKING_MODAL";
export const SHOW_FOLLOW_UP_BOOKING_MODAL = "gui/SHOW_FOLLOW_UP_BOOKING_MODAL";
export const SHOW_DOCTOR_INVOICE_MODAL = "gui/SHOW_DOCTOR_INVOICE_MODAL";
export const SHOW_CREATE_APPOINTMENT_MODAL =
  "gui/SHOW_CREATE_APPOINTMENT_MODAL";
export const SHOW_MEDICARE_INVOICE_MODAL = "gui/SHOW_MEDICARE_INVOICE_MODAL";
export const SHOW_SEND_APPOINTMETN_LINK = "gui/SHOW_SEND_APPOINTMETN_LINK";
export const SHOW_REMINDER_MODAL = "gui/SHOW_REMINDER_MODAL";
export const SHOW_APPOINTMENT_TIME_MODAL = "gui/SHOW_APPOINTMENT_TIME_MODAL";
export const SHOW_PAY_NOW_CONFIRM_MODAL = "gui/SHOW_PAY_NOW_CONFIRM_MODAL";
export const SHOW_ADMIN_TIME_SELECT_MODAL = "gui/SHOW_ADMIN_TIME_SELECT_MODAL";
export const SHOW_DATE_OFFER_SELECT_MODAL = "gui/SHOW_DATE_OFFER_SELECT_MODAL";
export const SHOW_PREVIEW_TIME_SELECT_MODAL =
  "gui/SHOW_PREVIEW_TIME_SELECT_MODAL";
export const SHOW_SEND_PATIENT_DATA_MODAL = "gui/SHOW_SEND_PATIENT_DATA_MODAL";
export const SHOW_CHANGE_EMAIL_MODAL = "gui/SHOW_CHANGE_EMAIL_MODAL";
export const SHOW_INSTANT_CONSULT_MODAL = "gui/SHOW_INSTANT_CONSULT_MODAL";

const initialState = {
  loginModal: false,
  callTest: false,
  inMeeting: {
    chatMessage: { unix: null, message: {} },
    viewType: "video",
    notes: false,
    trigerLeaveCall: 0,
    trigerEndCall: 0,
    medicalRecords: false,
    patientInformation: false,
    chat: false,
    inputDeviceId: null,
    outputDeviceId: null,
    videoDeviceId: null,
  },
  newPatientModal: {
    show: false,
  },
  medicareInstructions: {
    show: false,
  },
  documentViewer: {
    show: false,
    urls: null,
  },
  showDoctorNotes: {
    show: false,
    appointmentId: null,
    patientId: null,
  },
  showPatientInformation: {
    show: false,
    appointmentId: null,
  },
  doctorSurvey: {
    show: false,
    appointmentId: null,
  },
  addParticipant: {
    show: false,
    appointmentId: null,
    updateUnix: 0,
  },
  patientSurvey: {
    show: false,
    appointmentId: null,
  },
  showPatientMedicalRecord: {
    show: false,
    patientId: null,
    appointmentId: null,
  },
  guestLinkModal: {
    show: false,
  },
  postConsultLinkModal: {
    appointmentId: null,
    show: false,
  },
  followUpAppointmentModal: {
    appointmentId: null,
    show: false,
  },
  confirmationModal: {
    show: false,
    children: null,
    proceedText: null,
    cancelText: null,
    onClose: null,
    onProceed: null,
    onCancel: null,
  },
  documentBuilder: {
    show: false,
    unix: null,
    unixfile: null,
  },
  loadingModal: false,
  emergencyBot: { show: false, initMessage: "welcome_isConsultOnlineDoctor" },
  specialtyBot: { show: false, specialtyId: "", params: {} },
  advancePaymentModal: {
    details: "",
    loading: false,
    navigation: null,
    show: false,
    onClose: null,
    onConfirm: null,
    price: null,
    unix: null,
    onSavePayment: null,
  },
  showPricingModal: false,
  specialtyPricingModal: false,
  showPaymentModal: { show: false, price: 0 },
  specialtyBookingModal: {
    specialtyId: null,
    show: false,
    specialtyName: null,
  },
  showPostCodeMedicareChecker: false,

  createAppointmentModal: {
    show: false,
    user: null,
  },
  updateAppointmentModal: {
    show: false,
    appointmentId: null,
  },

  offerReferralModal: { show: false },
  amendAppointmentModal: { show: false, appointmentId: null },
  sendReferralModal: { show: false, appointmentId: null },
  preAprovedDatesModal: { show: false, appointmentId: null },
  markAsCompletedModal: { show: false, appointmentId: null },
  emailDocumentModal: { show: false, appointmentId: null, initialValues: {} },
  emergencyBookingModal: { show: false },
  followUpBookingModal: { show: false, appointmentId: null, initialValues: {} },
  doctorInvoiceModal: { show: false, appointmentId: null },
  medicareInvoiceModal: { show: false, appointmentId: null },
  sendAppointmentLinkModal: { show: false, appointmentId: null },
  reminderModal: { show: false, appointmentId: null },
  appointmentTimeModal: { show: false, appointmentId: null },
  datesOfferTimeSelectModal: { show: false, appointmentId: null },
  payNowConfirmModal: { show: false, appointmentId: null },
  adminTimeSelectModal: {
    show: false,
    appointmentId: null,
  },
  previewTimeSelectModal: {
    show: false,
    doctorId: null,
  },
  sendPatientDataModal: {
    show: false,
    appointmentId: null,
  },
  changeEmailModal: {
    show: false,
    patientId: null,
    patientEmail: null,
    navigation: null,
    fetchUser: null,
  },
  instantConsultModal: {
    show: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DOCUMENT_VIEWER:
      return {
        ...state,
        documentViewer: {
          ...state.documentViewer,
          ...action.params,
        },
      };
    case SHOW_DOCTOR_SURVEY:
      return {
        ...state,
        doctorSurvey: {
          ...state.doctorSurvey,
          ...action.params,
        },
      };
    case SHOW_PATIENT_SURVEY:
      return {
        ...state,
        patientSurvey: {
          ...state.patientSurvey,
          ...action.params,
        },
      };
    case ADD_PARTICIPANT:
      return {
        ...state,
        addParticipant: {
          ...state.addParticipant,
          ...action.params,
        },
      };
    case SHOW_PATIENT_MEDICAL_RECORDS:
      return {
        ...state,
        showPatientMedicalRecord: {
          ...state.showPatientMedicalRecord,
          ...action.params,
        },
      };
    case SHOW_DOCTOR_NOTES:
      return {
        ...state,
        showDoctorNotes: { ...state.showDoctorNotes, ...action.params },
      };
    case SHOW_PATIENT_INFORMATION:
      return {
        ...state,
        showPatientInformation: {
          ...state.showPatientInformation,
          ...action.params,
        },
      };
    case SHOW_MEDICARE_INSTRUCTIONS:
      return {
        ...state,
        medicareInstructions: {
          ...state.medicareInstructions,
          ...action.params,
        },
      };
    case SHOW_ADVANCE_PAYMENT_MODAL:
      return {
        ...state,
        advancePaymentModal: { ...state.advancePaymentModal, ...action.params },
      };
    case SHOW_SPECIALTY_BOOKING_MODAL:
      return {
        ...state,
        specialtyBookingModal: {
          ...state.specialtyBookingModal,
          ...action.params,
        },
      };
    // case SHOW_CHAT_SIDE_BAR:
    //   return {
    //     ...state,
    //     chatSideBar: { ...state.chatSideBar, ...action.params },
    //   };
    // case TOGGLE_CHAT_SIDE_BAR:
    //   return {
    //     ...state,
    //     chatSideBar: {
    //       ...state.chatSideBar,
    //       minimizeChat: !state.chatSideBar.minimizeChat,
    //     },
    //   };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.show,
      };

    case SHOW_POSTCODE_CHECKER:
      return {
        ...state,
        showPostCodeMedicareChecker: action.show,
      };
    case SHOW_EMERGENCY_BOT:
      return {
        ...state,
        emergencyBot: { show: action.show, initMessage: action.initMessage },
      };
    case SHOW_SPECIALTY_BOT:
      return {
        ...state,
        specialtyBot: { ...state.specialtyBot, ...action.params },
      };
    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: { ...state.confirmationModal, ...action.params },
      };
    case SHOW_LOADING_MODAL:
      return {
        ...state,
        loadingModal: action.show,
      };
    case SHOW_PRICING_MODAL:
      return {
        ...state,
        showPricingModal: action.show,
      };
    case SHOW_SPECIALTY_PRICING_MODAL:
      return {
        ...state,
        specialtyPricingModal: action.show,
      };
    case SHOW_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: action.show,
        price: action.price,
      };

    case SHOW_GUEST_LINK_MODAL:
      return {
        ...state,
        guestLinkModal: {
          show: action.show,
        },
      };

    case SHOW_POST_CONSULT_LINK_MODAL:
      return {
        ...state,
        postConsultLinkModal: {
          ...state.showPostConsultLinkModal,
          ...action.params,
        },
      };

    case SHOW_FILE_VIEWER:
      return {
        ...state,
        file: action.file,
      };
    case SHOW_IN_MEETING:
      return {
        ...state,
        inMeeting: {
          ...state.inMeeting,
          ...action.params,
        },
      };
    case SHOW_NEW_PATIENT:
      return {
        ...state,
        newPatientModal: {
          ...state.newPatientModal,
          show: action.show,
        },
      };
    case DISPLAY_CHAT_MESSAGE:
      return {
        ...state,

        inMeeting: {
          ...state.inMeeting,
          chatMessage: { ...action.params },
        },
      };
    case SHOW_OFFER_REFERRAL_MODAL:
      return {
        ...state,
        offerReferralModal: { ...state.offerReferralModal, ...action.params },
      };
    case SHOW_AMEND_APPOINTMENT_MODAL:
      return {
        ...state,
        amendAppointmentModal: {
          ...state.amendAppointmentModal,
          ...action.params,
        },
      };
    case SHOW_SEND_REFERRAL_MODAL:
      return {
        ...state,
        sendReferralModal: {
          ...state.sendReferralModal,
          ...action.params,
        },
      };
    case SHOW_UPDATE_APPOINTMET_MODAL:
      return {
        ...state,
        updateAppointmentModal: {
          ...state.updateAppointmentModal,
          ...action.params,
        },
      };
    case SHOW_PREAPROVED_DATES_MODAL:
      return {
        ...state,
        preAprovedDatesModal: {
          ...state.preAprovedDatesModal,
          ...action.params,
        },
      };
    case SHOW_MARK_AS_COMPLETED_MODAL:
      return {
        ...state,
        markAsCompletedModal: {
          ...state.markAsCompletedModal,
          ...action.params,
        },
      };
    case UPDATE_DOCUMENT_BUILDER:
      return {
        ...state,
        documentBuilder: {
          ...state.documentBuilder,
          ...action.params,
        },
      };
    case SHOW_EMAIL_DOCUMENT_MODAL:
      if (action?.params?.show === false) {
        action.params = initialState.emailDocumentModal;
      }
      return {
        ...state,
        emailDocumentModal: {
          ...state.emailDocumentModal,
          ...action.params,
        },
      };
    case SHOW_EMERGENCY_BOOKING_MODAL:
      return {
        ...state,
        emergencyBookingModal: {
          ...state.emergencyBookingModal,
          ...action.params,
        },
      };
    case SHOW_FOLLOW_UP_BOOKING_MODAL:
      return {
        ...state,
        followUpBookingModal: {
          ...state.followUpBookingModal,
          ...action.params,
        },
      };
    case SHOW_DOCTOR_INVOICE_MODAL:
      return {
        ...state,
        doctorInvoiceModal: {
          ...state.doctorInvoiceModal,
          ...action.params,
        },
      };
    case SHOW_CREATE_APPOINTMENT_MODAL:
      return {
        ...state,
        createAppointmentModal: {
          ...state.createAppointmentModal,
          ...action.params,
        },
      };
    case SHOW_MEDICARE_INVOICE_MODAL:
      return {
        ...state,
        medicareInvoiceModal: {
          ...state.medicareInvoiceModal,
          ...action.params,
        },
      };
    case SHOW_SEND_APPOINTMETN_LINK:
      return {
        ...state,
        sendAppointmentLinkModal: {
          ...state.sendAppointmentLinkModal,
          ...action.params,
        },
      };
    case SHOW_REMINDER_MODAL:
      return {
        ...state,
        reminderModal: {
          ...state.reminderModal,
          ...action.params,
        },
      };
    case SHOW_APPOINTMENT_TIME_MODAL:
      return {
        ...state,
        appointmentTimeModal: {
          ...state.appointmentTimeModal,
          ...action.params,
        },
      };
    case SHOW_PAY_NOW_CONFIRM_MODAL:
      return {
        ...state,
        payNowConfirmModal: {
          ...state.payNowConfirmModal,
          ...action.params,
        },
      };
    case SHOW_ADMIN_TIME_SELECT_MODAL:
      return {
        ...state,
        adminTimeSelectModal: {
          ...state.adminTimeSelectModal,
          ...action.params,
        },
      };
    case SHOW_PREVIEW_TIME_SELECT_MODAL:
      return {
        ...state,
        previewTimeSelectModal: {
          ...state.previewTimeSelectModal,
          ...action.params,
        },
      };
    case SHOW_SEND_PATIENT_DATA_MODAL:
      return {
        ...state,
        sendPatientDataModal: {
          ...state.sendPatientDataModal,
          ...action.params,
        },
      };
    case SHOW_DATE_OFFER_SELECT_MODAL:
      return {
        ...state,
        datesOfferTimeSelectModal: {
          ...state.datesOfferTimeSelectModal,
          ...action.params,
        },
      };
    case SHOW_CHANGE_EMAIL_MODAL:
      return {
        ...state,
        changeEmailModal: {
          ...state.changeEmailModal,
          ...action.params,
        },
      };
    case SHOW_INSTANT_CONSULT_MODAL:
      return {
        ...state,
        instantConsultModal: {
          ...state.instantConsultModal,
          ...action.params,
        },
      };

    default:
      return state;
  }
};

export const displayChatMessage = (parameters) => {
  return (dispatch) => {
    try {
      dispatch({
        type: DISPLAY_CHAT_MESSAGE,
        params: { ...parameters },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showUpdateAppointmentModal = (parameters) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_UPDATE_APPOINTMET_MODAL,
        params: { ...parameters },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showPricingModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PRICING_MODAL,
        show: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showSpecialtyPricingModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_SPECIALTY_PRICING_MODAL,
        show: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeSpecialtyPricingModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_SPECIALTY_PRICING_MODAL,
        show: false,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showDocumentViewer = (parameters) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCUMENT_VIEWER,
        params: { show: true, ...parameters },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeDocumentViewer = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCUMENT_VIEWER,
        params: initialState.documentViewer,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPatientMedicalRecord = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_MEDICAL_RECORDS,
        params: { show: true, ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showPostConsultLinkModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_POST_CONSULT_LINK_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const hidePatientMedicalRecord = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_MEDICAL_RECORDS,
        params: initialState.showPatientMedicalRecord,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showDoctorNotes = ({ show, appointmentId, patientId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_NOTES,
        params: { show, appointmentId, patientId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPatientInformation = ({ show, appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_INFORMATION,
        params: { show, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const hideDoctorNotes = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_NOTES,
        params: initialState.showDoctorNotes,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hidePatientInformation = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_INFORMATION,
        params: initialState.showPatientInformation,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPatientSurvey = ({ appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_SURVEY,
        params: { show: true, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showMedicareInstructions = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_MEDICARE_INSTRUCTIONS,
        params: { show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const hideMedicareInstructions = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_MEDICARE_INSTRUCTIONS,
        params: { show: false },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const hidePatientSurvey = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PATIENT_SURVEY,
        params: initialState.patientSurvey,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showDoctorSurvey = ({ appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_SURVEY,
        params: { show: true, appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hideDoctorSurvey = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_SURVEY,
        params: initialState.doctorSurvey,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showAddParticipant = ({ appointmentId }) => {
  return (dispatch) => {
    try {
      dispatch({
        type: ADD_PARTICIPANT,
        params: { show: true, appointmentId: appointmentId },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const hideAddParticipant = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: ADD_PARTICIPANT,
        params: {
          ...initialState.addParticipant,
          updateUnix: new Date().getTime(),
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showNewPatientModal = (show) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_NEW_PATIENT,
        show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showLoadingModal = (show) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_LOADING_MODAL,
        show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showLoginModal = (show) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_LOGIN_MODAL,
        show,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showConfirmationModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CONFIRMATION_MODAL,
        params: { ...params, show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const closeConfirmationModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CONFIRMATION_MODAL,
        params: initialState.confirmationModal,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showAdvancePaymentModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_ADVANCE_PAYMENT_MODAL,
        params: { ...params, show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showSpecialtyBookingModal = (params) => {
  return (dispatch) => {
    try {
      if (!params.show) {
        params = { show: false, user: null, speciltyId: null };
      }
      dispatch({
        type: SHOW_SPECIALTY_BOOKING_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeAdvancePaymentModal = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_ADVANCE_PAYMENT_MODAL,
        params: { ...initialState.advancePaymentModal },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showChatSideBar = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CHAT_SIDE_BAR,
        params: { ...params, show: true },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const toggleChatSideBar = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: TOGGLE_CHAT_SIDE_BAR,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const closeChatSideBar = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CHAT_SIDE_BAR,
        params: { ...initialState.advancePaymentModal },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showSpecialtyBot = (specialtyId, params = {}) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_SPECIALTY_BOT,
        params: { specialtyId, show: true, params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showInMeeting = (params = {}) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_IN_MEETING,
        params,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showOfferReferralModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_OFFER_REFERRAL_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showAmendAppointmentModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_AMEND_APPOINTMENT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showSendReferralModal = (params) => {
  return (dispatch) => {
    try {
      if (params.show === false) {
        params.appointmentId = null;
      }
      dispatch({
        type: SHOW_SEND_REFERRAL_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPreAprovedDatesModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PREAPROVED_DATES_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showMarkAsCompletedModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_MARK_AS_COMPLETED_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const updateDocumentBuilder = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: UPDATE_DOCUMENT_BUILDER,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showEmailDocumentModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_EMAIL_DOCUMENT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showEmergencyBookingModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_EMERGENCY_BOOKING_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showFollowUpBookingModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_FOLLOW_UP_BOOKING_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showDoctorInvoiceModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DOCTOR_INVOICE_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showCreateAppointmentModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CREATE_APPOINTMENT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showMedicareInvoiceModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_MEDICARE_INVOICE_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showSendAppointmentLinkModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_SEND_APPOINTMETN_LINK,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showReminderModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_REMINDER_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showAppointmentTimeModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_APPOINTMENT_TIME_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPayNowConfirmModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PAY_NOW_CONFIRM_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showAdminTimeSelectModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_ADMIN_TIME_SELECT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showPreviewTimeSelectModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_PREVIEW_TIME_SELECT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showSendPatientDataModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_SEND_PATIENT_DATA_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const showDateOfferSelectModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_DATE_OFFER_SELECT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showChangeEmailModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_CHANGE_EMAIL_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const showInstantConsultModal = (params) => {
  return (dispatch) => {
    try {
      dispatch({
        type: SHOW_INSTANT_CONSULT_MODAL,
        params: { ...params },
      });
    } catch (error) {
      console.error(error);
    }
  };
};
