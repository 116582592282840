import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import NavItem from "@layouts/appLayoutStyled/header/nav_item";

function NurseAppNavigation({ activeIndex, toggleSubNav }) {
  const user = useSelector((state) => state.session.user);
  const dashboardLink = user.dashboardLink || "/emerg-room";
  const linkDash =
    dashboardLink[0] === "/" ? dashboardLink : `/${dashboardLink}`;

  return (
    <>
      <NavItem
        index={0}
        isActive={activeIndex === 0}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/">Dashboard</Link>
      </NavItem>
      <NavItem
        index={1}
        isActive={activeIndex === 1}
        toggleSubNav={toggleSubNav}
      >
        <Link to={linkDash}>New appointment</Link>
      </NavItem>
      <NavItem
        index={2}
        isActive={activeIndex === 2}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/billing">Payments</Link>
      </NavItem>
    </>
  );
}

export default NurseAppNavigation;
