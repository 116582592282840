import React from "react";
import { Link } from "react-router-dom";
import NavItem from "@layouts/appLayoutStyled/header/nav_item";

function PatientNavigation({ toggleSubNav, activeIndex }) {
  return (
    <>
      <NavItem
        index={0}
        isActive={activeIndex === 0}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/patient/appointments">APPOINTMENTS</Link>
      </NavItem>
    </>
  );
}

export default PatientNavigation;
