import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavItem from "@layouts/appLayoutStyled/header/nav_item";

function CompanyAdminNav({ activeIndex, toggleSubNav }) {
  const user = useSelector((state) => state.session.user);
  const dashboardLink = user.dashboardLink || "/company-admin/discounts";
  const linkDash =
    dashboardLink[0] === "/" ? dashboardLink : `/${dashboardLink}`;

  return (
    <>
      <NavItem
        index={0}
        isActive={activeIndex === 0}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/company-admin/appointments">Appointments</Link>
      </NavItem>
      <NavItem
        index={1}
        isActive={activeIndex === 1}
        toggleSubNav={toggleSubNav}
      >
        <Link to={linkDash}>Discounts</Link>
      </NavItem>
    </>
  );
}

export default CompanyAdminNav;
