import api from "@src/api";
import { Routes } from "@constants";
import Toast from "@src/helpers/toast";

const getCompanyWithToken = (id) => {
  return api.get(Routes.companyWithToken(id));
};
const deleteCompanyWithToken = (id) => {
  return api.del(Routes.companyWithToken(id));
};
const editCompany = async (file, companyId, payload, token) => {
  try {
    if (file) {
      var formData = new FormData();
      formData.append(file.name, file);

      const response = await api.multiPart(
        Routes.uploadCompanyLogo(token),
        formData
      );
      var fileName = response.result.files[file.name][0].name;
      payload.imagePath = fileName;
      if (payload?.slug) {
        payload.slug = payload?.slug?.toLowerCase();
      }
      if (payload?.code) {
        payload.code = payload?.code?.toLowerCase();
      }

      return await api.put(Routes.companyWithToken(companyId), payload);
    } else {
      return await api.put(Routes.companyWithToken(companyId), payload);
    }
  }
  catch (error) {
    console.error(error);
    Toast.displayToast({
      message: "Unexpected error occur please try again",
      kind: "error",
    });
  }
};

const createCompany = async (file, payload) => {
  try {
    if (payload.slug) {
      payload.slug = payload?.slug?.toLowerCase();
    }
    if (payload.code) {
      payload.code = payload?.code?.toLowerCase();
    }
    if (file) {
      var formData = new FormData();
      formData.append(file.name, file);
      return await api
        .multiPart(Routes.uploadCompanyLogo(), formData)
        .then((response) => {
          var fileName = response.result.files[file.name][0].name;
          payload.imagePath = fileName;

          return api.post(Routes.createCompanyWithToken(), payload);
        });
    } else {
      return await api.post(Routes.createCompanyWithToken(), payload);
    }
  }
  catch (error) {
    console.error(error);
    Toast.displayToast({
      message: "Unexpected error occur please try again",
      kind: "error",
    });
  }
};
const fetchCompanyImage = async (file) => {
  return await api.get(Routes.companyLogo(file));
};
const findBySlug = async (slug) => {
  return await api.get(Routes.companyFindBySlug(slug));
};
const fetchAll = async () => {
  return await api.get(Routes.companies);
};
export default {
  findBySlug,
  getCompanyWithToken,
  createCompany,
  deleteCompanyWithToken,
  fetchAll,
  editCompany,
  fetchCompanyImage,
};
