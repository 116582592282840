export const INIT_USER = "session/INIT_USER";
export const INIT_GUEST_USER = "session/INIT_GUEST_USER";
export const INIT_FETCH = "session/INIT_FETCH";
export const CLEAR_ALL = "session/CLEAR_ALL";
import Token from "@helpers/token";
import Session from "@helpers/session";
import { history } from "@modules/";
import api from "@api/index";

const initialState = {
  guestUser: {},
  user: {},
  env: {},
  fetch: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_USER:
      return {
        ...state,
        user: action.user,
        guestUser: {},
        env: action.env,
        fetch: false,
      };
    case INIT_GUEST_USER:
      return {
        ...state,
        user: {},
        guestUser: action.user,
        env: action.env,
        fetch: false,
      };
    case INIT_FETCH:
      return {
        ...state,
        fetch: action.fetch,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export const initFetch = (flag) => {
  console.log("initFetch", flag);
  return (dispatch) => {
    try {
      dispatch({
        type: INIT_FETCH,
        fetch: flag,
      });
    } catch (error) {
      console.error(error);
    }
  };
};
export const unmascaradeUser = () => {
  return (dispatch) => {
    try {
      const masktoken = api.getMaskToken();
      api.clearMaskToken();
      api.setTokens(masktoken.access, masktoken.refresh);
      dispatch({
        type: CLEAR_ALL,
      });
    } catch (error) {
      console.error(error);
      api.clearTokens();
      dispatch({
        type: CLEAR_ALL,
      });
    }
  };
};

export const initUser = ({ env, user, token }) => {
  const logingUser = token ? { ...user, token } : user;
  return (dispatch) => {
    dispatch({
      type: INIT_USER,
      env,
      user: logingUser,
    });
  };
};

// NOT BEING USED - MAYBE DEPRECATED

// export const initUserWithToken = ({ token }) => {
//   Token.clearMaskToken();
//   Token.clearGuestToken();
//   Token.setToken(token);
//   return (dispatch) => {
//     dispatch({
//       type: CLEAR_ALL,
//     });
//   };
// };

export const initGuestUser = ({ env, user, token }) => {
  const logingUser = token ? { ...user, token } : user;
  Token.clearToken();
  Token.setGuestToken(token);
  return (dispatch) => {
    dispatch({
      type: INIT_GUEST_USER,
      env,
      user: logingUser,
    });
  };
};

// CAN NOT BEING USED WITH NEW MASKTOKEN SET IN API - MAYBE DEPRECATED
export const maskUser = (tokens) => {
  Token.setToken(tokens.maskToken);
  Token.setMaskToken(tokens.currentToken);
  return (dispatch) => {
    dispatch({
      type: CLEAR_ALL,
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    // logout api call
    // if (api.getRefreshToken()) await Session.logout();

    api.clearTokens();

    // // clear all tokens
    // Token.clearToken();
    // Token.clearMaskToken();
    // Token.clearGuestToken();
    // Token.clearRefreshToken();

    // dispatch action to clear session data
    dispatch({
      type: CLEAR_ALL,
    });
    history.push("/");
  };
};
