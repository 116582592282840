import React from "react";
const NavItem = ({ index, isActive, toggleSubNav, children }) => {
  const handleToggle = (event) => {
    event.stopPropagation();
    toggleSubNav(index);
  };

  return (
    <li
      className={`nav-item ${isActive ? "active" : ""}`}
      onClick={handleToggle}
    >
      {children}
    </li>
  );
};

export default NavItem;
