import React from "react";
import { Link } from "react-router-dom";
import NavItem from "@layouts/appLayoutStyled/header/nav_item";

function DefaultNavigation({ activeIndex, toggleSubNav }) {
  return (
    <>
      <NavItem
        index={0}
        isActive={activeIndex === 0}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/admin/appointments"> Date & Time Audits </Link>
        <div className="sub-nav">
          <ul className="sub-nav-links">
            <li className="subnav-item">
              <Link to="/admin/appointments">Appointments</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?filter=upcomming">Upcoming</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?filter=pedingToAllocate&type=specialties">
                Pending to allocate to doctor
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?filter=pendingPatientToAccept">
                Pending patient accept
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?filter=pendigDoctorAccept">
                Pending doctor accept
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?filter=paidReferrals&type=specialties">
                Pending paid referrals
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?filter=reserved">
                Pending to confirm (reserved)
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/appointments?type=emergency">Emergency</Link>
            </li>

            <li className="subnav-item">
              <Link to="/admin/faq">FAQs Management</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/blog">Blog Management</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/emergency-doctors">Emergency Rooster</Link>
            </li>
          </ul>
        </div>
      </NavItem>

      <NavItem
        index={1}
        isActive={activeIndex === 1}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/admin/specialists"> Specialists Management </Link>
        <div className="sub-nav">
          <ul className="sub-nav-links">
            <li className="subnav-item">
              <Link to="/admin/specialists">Specialists Accounts</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/instant-doctors">Instant Doctors</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/emergency-room-manager">
                Emerg room management
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/company-admin">Company Admin management</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/specialties">Specialties</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/companies">Companies</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/discounts">Discounts</Link>
            </li>
          </ul>
        </div>
      </NavItem>

      <NavItem
        index={2}
        isActive={activeIndex === 2}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/admin/patients"> Patients Management </Link>
        <div className="sub-nav">
          <ul className="sub-nav-links">
            <li className="subnav-item">
              <Link to="/admin/referrals">Referrals</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/patients">Patients Accounts</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/recordings">Emergency Phone Calls</Link>
            </li>
            <li className="subnav-item">
              <Link to="/admin/faxes">Received Faxes</Link>
            </li>
          </ul>
        </div>
      </NavItem>
    </>
  );
}

export default DefaultNavigation;
