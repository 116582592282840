import toastr from "toastr";
import "@src/styles/ui-plugins/_toastr.min.scss";
const displayToast = ({ kind, message, title = "" }) => {
  toastr.options = {};
  switch (kind) {
    case "success":
      return toastr.success(message, title);
    case "error":
      return toastr.error(message, title);
    case "warning":
      return toastr.warning(message, title);
    default:
      return toastr.info(message, title);
  }
};
const displayToastFullWidth = ({ kind, message, title = "" }) => {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-full-width",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "0",
    extendedTimeOut: "0",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  switch (kind) {
    case "success":
      return toastr.success(message, title);
    case "error":
      return toastr.error(message, title);
    case "warning":
      return toastr.warning(message, title);
    default:
      return toastr.info(message, title);
  }
};

// Store a map of active toast IDs to prevent duplicates

function showJoinRequestToast(username, userId, onAccept, onReject) {
  // Check if a toast with the specified userId already exists in the DOM
  if (document.getElementById(`toast-${userId}`)) {
    console.log(`Toast for user with ID ${userId} is already displayed.`);
    return; // Prevent showing a duplicate toast
  }

  // Create the message container with a unique ID based on userId
  const message = document.createElement("div");
  message.id = `toast-${userId}`; // Add the unique ID using userId
  message.innerHTML = `<p>${username} is waiting to join</p>`;

  // Create accept and reject buttons
  const acceptButton = document.createElement("button");
  acceptButton.innerText = "Accept";
  acceptButton.style.marginRight = "10px";
  acceptButton.onclick = function () {
    toastr.remove(); // Remove the toast
    onAccept(userId); // Call the accept function with the userId
  };

  const rejectButton = document.createElement("button");
  rejectButton.innerText = "Reject";
  rejectButton.onclick = function () {
    toastr.remove(); // Remove the toast
    onReject(userId); // Call the reject function with the userId
  };

  // Append buttons to the message container
  message.appendChild(acceptButton);
  message.appendChild(rejectButton);

  // Display the toast with the custom message, keep it visible, and add a close button
  toastr.info(message, null, {
    closeButton: true, // Adds the "X" close button to the toast
    timeOut: 0, // Keeps the toast visible indefinitely until manually closed
    extendedTimeOut: 0, // Disable auto-hiding even when hovered
    tapToDismiss: false, // Prevent clicking on the toast body to dismiss
  });
}

const displayUnexpectedError = () => {
  return toastr.error("Unexpected error occur. Please try again");
};
export default {
  displayToast,
  displayUnexpectedError,
  displayToastFullWidth,
  showJoinRequestToast,
};
