import React from "react";
import { Link } from "react-router-dom";
import NavItem from "@layouts/appLayoutStyled/header/nav_item";

function DoctorNavigation({ activeIndex, toggleSubNav }) {
  return (
    <>
      <NavItem
        index={0}
        isActive={activeIndex === 0}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/appointments">Appointments</Link>
        <div className="sub-nav">
          <ul className="sub-nav-links">
            <li className="subnav-item">
              <Link to="/appointments/list/upcoming/1">
                Future Appointments
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/appointments/list/past/1">Past Appointments</Link>
            </li>
            <li className="subnav-item">
              <Link to="/appointments/list/cancelled/1">
                Cancelled Appointments
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/appointments/list/pending/1">
                Waiting for patient to accept
              </Link>
            </li>
            <li className="subnav-item">
              <Link to="/appointments/list/offers/1">
                Waiting for doctor to accept
              </Link>
            </li>
          </ul>
        </div>
      </NavItem>
      <NavItem
        index={1}
        isActive={activeIndex === 1}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/calendar">Calendar</Link>
      </NavItem>
      <NavItem
        index={1}
        isActive={activeIndex === 1}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/account/doctor/profile">Profile</Link>
      </NavItem>
      <NavItem
        index={2}
        isActive={activeIndex === 2}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/doctor/availability">Availability</Link>
      </NavItem>
      <NavItem
        index={3}
        isActive={activeIndex === 3}
        toggleSubNav={toggleSubNav}
      >
        <Link to="/patients/1">Patients</Link>
      </NavItem>
    </>
  );
}

export default DoctorNavigation;
