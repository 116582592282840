import styled from "styled-components";
import IconCicleTimes from "@assets/images/lndn-2020/times-circle.svg";
import IconCicleTimesWhite from "@assets/images/lndn-2020/times-circle-white.svg";
import { Primarybutton } from "@layouts/homeLayout/ui/buttons";
import { FormControl } from "@layouts/homeLayout/ui/inputs";

export const StandaloneBrand = styled.div`
  width: 105px;
  height: 32px;
  background-size: contain;
  background-position: center bottom;
  text-align: center;
  margin-top: 40px;
  background-image: url(../../assets/images/login-stand-alone-brand.png);
  background-repeat: no-repeat;
`;

export const StandaloneModal = styled.div`
  background-color: "white";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 100vh !important;
  font-family: "Lexend", sans-serif;
`;

export const Modal = styled.div`
  display: flex;
  align-items: center;
  z-index: 999999;
  position: ${(props) => (props.standalone ? "unset" : "fixed")};
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  width: 100%;
  height: ${(props) => (props.standalone ? "auto" : "100%")};
  background: ${(props) => (props.standalone ? "white" : "rgba(0, 0, 0, 0.5)")};

  &.p-0 {
    padding: 0;
  }
`;

export const ModalInner = styled.div`
  position: relative;
  width: 100%;
  max-width: ${(props) => (props.wide ? "680px" : "380px")};
  height: auto;
  margin: auto;
  padding: 20px;
  background: ${(props) =>
    props.colored
      ? "linear-gradient(135deg,rgba(20,165,201,.9),rgba(20,164,200,.9) 1%,rgba(56,32,81,.9))"
      : "#fff"};
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 8px;

  &.p-0 {
    padding: 0;
  }

  .md-header,
  .modal-header {
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    padding: 3px 25px 3px 10px;
    border-radius: 8px p {
      color: #fff;
      margin: 0;
    }
  }

  h4 {
    margin: 10px 0;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    color: #555 !important;
  }

  p {
    font-size: 0.9em;
    line-height: 1.3;
    text-align: center;
    margin: 15px 0 35px !important;
    color: #555;

    &:empty {
      display: none;
    }
  }

  .md-close,
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 18px;
    padding: 0;
    background-color: transparent !important;
    background-image: url(${IconCicleTimes});
    opacity: 0.8;
    background-repeat: no-repeat;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }

  ${FormControl} {
    width: 100%;
  }

  ${Primarybutton} {
    font-size: 14px;
    padding: 5px 15px;
    height: 42px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .loading-login {
      height: 30px;
    }
  }
`;

export const ModalInnerPricing = styled(ModalInner)`
  padding: 20px;

  .modal-header {
    position: relative;
    padding: 6px 0;

    .modal-close {
      top: -10px;
      right: -10px;
      background-image: url(${IconCicleTimesWhite});
    }

    .modal-header-h4 {
      display: block;
      margin-top: 0;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      font-weight: 300;
      font-size: 0.8em;
    }
  }

  .modal-body {
    padding-top: 30px;
  }

  .modal-pricing__block {
    display: block;
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .modal-pricing__block__price {
    display: block;
    color: #fff;
    font-size: 2.2em;
    font-weight: 400;
    margin-bottom: 5px;

    sup {
      vertical-align: super;
      font-size: 50%;
      top: 0;
      line-height: 1;
    }
  }

  .modal-pricing__block__detail {
    display: block;
    color: #fff;
    font-size: 1.1em;
    font-weight: 300;
  }

  .modal-pricing__block__desc_bold {
    font-weight: 400;
    line-height: 1.32;
    font-size: 16px;
    color: #fff;
    margin-bottom: 40px;
  }

  ${Primarybutton} {
    font-size: 15px;
    padding: 12px 15px;
    margin-top: 15px;
  }
`;

export const ModalFooterMesage = styled.div`
  margin: 20px -20px -20px;
  background: rgba(221, 221, 221, 0.21);
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px;
  text-align: center;
  font-size: 12px;

  span {
    display: block;
    margin: 0 0 5px;
  }

  em {
    font-style: normal;
  }

  a {
    text-decoration: underline;
  }
`;
